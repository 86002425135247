import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

type Color = 'primary' | 'secondary'
type Size = 'large' | 'medium' | 'small'
type Variant = 'complete' | 'mark' | 'text'
type TextTheme = 'light' | 'dark'

interface LogoProps {
  color?: Color
  size?: Size
  variant?: Variant
  textTheme?: TextTheme
  login?: boolean
}

// eslint-disable-next-line complexity
export const OldLogo = styled((props: LogoProps) => {
  const { color, variant, login, textTheme = 'light', ...rest } = props

  // TODO: use size prop to calculate height and width

  const renderColor = color === 'primary' ? '#7CDABC' : '#C1C4D6'
  const renderMark = variant === 'complete' || variant === 'mark'
  const renderText = variant === 'complete' || variant === 'text'
  const textColor = 'black'
  const renderWidth =
    variant === 'complete' ? '200' : variant === 'mark' ? '46' : '174'
  const view = login ? `0 0 ${renderWidth} 100` : `0 0 ${renderWidth} 200`

  return (
    <svg
      width={renderWidth}
      height='180'
      viewBox={view}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      {renderText && (
        <>
          <defs>
            <radialGradient
              id='radial-gradient'
              cx='41.78'
              cy='77.11'
              fx='41.78'
              fy='77.11'
              r='80.28'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='.55' stopColor='#562023' />
              <stop offset='.62' stopColor='#935b22' />
              <stop offset='.73' stopColor='#ffc222' />
              <stop offset='1' stopColor='#fff' />
            </radialGradient>
          </defs>
          <g id='Art'>
            <path
              className='cls-2'
              d='M93.74,148.82h0c40-42.9,40.5-97.3-.96-138.76h0c27.05,42.15,27.35,96.32,.94,138.76Z'
              fill="url('#radialGradient')"
              opacity='.5'
            />
            <path
              className='cls-1'
              d='M93.75,148.82c26.41-42.44,26.09-96.61-.96-138.76,23.79,42.4,24.48,96.49,.96,138.76Z'
              fill={textColor}
            />
            <path
              className='cls-1'
              d='M37.29,90.19c0,1.98-.45,3.79-1.33,5.42-1.07,1.91-2.49,3.03-4.27,3.34l-.69-2.18c1.25-.28,2.24-1.09,3-2.45,.61-1.2,.91-2.49,.91-3.87,0-2.09-.88-5.37-2.63-9.83-1.73-4.46-2.59-7.86-2.59-10.2,0-1.98,.44-3.79,1.33-5.42,1.07-1.91,2.49-3.03,4.27-3.35l.69,2.18c-1.25,.29-2.25,1.1-3,2.45-.61,1.27-.91,2.58-.91,3.93,0,2.05,.88,5.31,2.63,9.78,1.73,4.46,2.59,7.86,2.59,10.2Z'
              fill={textColor}
            />
            <path
              className='cls-1'
              d='M72.37,80.29c0,4.19-.44,7.88-1.33,11.08-1.31,5.04-3.52,7.56-6.6,7.56s-5.3-2.52-6.61-7.56c-.89-3.2-1.33-6.89-1.33-11.08s.44-7.94,1.33-11.13c1.35-5.01,3.56-7.52,6.61-7.52s5.29,2.51,6.6,7.52c.89,3.2,1.33,6.91,1.33,11.13Zm-2.36,0c0-3.34-.32-6.47-.96-9.38-.96-4.65-2.5-6.98-4.63-6.98s-3.67,2.32-4.62,6.98c-.64,2.91-.96,6.04-.96,9.38s.31,6.43,.96,9.38c.96,4.66,2.5,6.98,4.62,6.98s3.67-2.32,4.63-6.98c.64-2.95,.96-6.07,.96-9.38Z'
              fill={textColor}
            />
            <path
              className='cls-1'
              d='M100.76,98.95h-7.86V61.66h2.34v35.16h5.51v2.13Z'
              fill={textColor}
            />
            <path
              className='cls-1'
              d='M134.15,98.95h-7.84V61.66h7.84v2.13h-5.5v14.51h5.5v2.16h-5.5v16.36h5.5v2.13Z'
              fill={textColor}
            />
            <path
              className='cls-1'
              d='M166.42,98.75h-2.48l-6.19-17.68c.93-.29,1.78-.71,2.56-1.26,.77-.56,1.43-1.2,1.99-1.93,.56-.73,.99-1.55,1.29-2.45,.3-.89,.46-1.83,.46-2.79s-.15-1.9-.46-2.79c-.3-.9-.73-1.72-1.29-2.47-.55-.75-1.22-1.41-1.99-1.96-.77-.55-1.62-.96-2.56-1.21v34.55h-2.31V61.45c3.08,.04,5.66,1.16,7.74,3.38,2.12,2.19,3.17,4.78,3.17,7.79,0,2.04-.51,3.92-1.53,5.64-1.02,1.72-2.41,3.08-4.16,4.08l5.77,16.39Z'
              fill={textColor}
            />
            <path
              className='cls-1'
              d='M196.95,98.36h-2.25l-.94-6.7-5.48-.05-1,6.75h-2.25l6.01-37.29,5.91,37.29Zm-3.5-8.77l-2.4-16.77-2.46,16.77h4.86Z'
              fill={textColor}
            />
            <g>
              <path
                className='cls-1'
                d='M129.79,116.83l-2.72-6.61v6.37h-.75v-9.79l2.72,6.48v-6.24h.75v9.79Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M136.29,116.59h-.75l-.31-1.79h-1.83s-.33,1.79-.33,1.79h-.75l2.01-9.98,1.97,9.98Zm-1.17-2.35l-.8-4.49-.82,4.49h1.62Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M141.58,107.58h-1.21v9.01h-.75v-9.01h-1.2v-.54h3.16v.54Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M144.53,116.59h-.75v-9.56h.75v9.56Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M152.36,111.81c0,1.1-.15,2.07-.44,2.91-.43,1.32-1.15,1.98-2.17,1.98s-1.74-.66-2.17-1.98c-.29-.84-.44-1.81-.44-2.91s.15-2.08,.44-2.92c.44-1.31,1.16-1.97,2.17-1.97s1.73,.66,2.17,1.97c.29,.84,.44,1.81,.44,2.92Zm-.77,0c0-.87-.11-1.69-.31-2.46-.31-1.22-.82-1.83-1.52-1.83s-1.2,.61-1.52,1.83c-.21,.76-.31,1.58-.31,2.46s.11,1.69,.31,2.46c.31,1.22,.82,1.83,1.52,1.83s1.21-.61,1.52-1.83c.21-.77,.31-1.59,.31-2.46Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M157.77,116.83l-2.72-6.61v6.37h-.75v-9.79l2.72,6.48v-6.24h.75v9.79Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M164.27,116.59h-.75l-.31-1.79h-1.83s-.33,1.79-.33,1.79h-.75l2.01-9.98,1.97,9.98Zm-1.17-2.35l-.8-4.49-.82,4.49h1.62Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M169.33,116.59h-2.51v-9.56h.75v9.01h1.76v.54Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M178.34,113.81c0,.78-.34,1.46-1.03,2.04-.69,.57-1.53,.86-2.51,.87v-9.82c.93,.08,1.71,.39,2.34,.95,.64,.54,.96,1.19,.96,1.94s-.3,1.36-.91,1.9c.77,.57,1.15,1.28,1.15,2.12Zm-.75,0c0-.45-.15-.85-.45-1.22-.3-.37-.69-.65-1.18-.84,.91-.45,1.36-1.1,1.36-1.96,0-.48-.16-.9-.49-1.29-.33-.38-.76-.66-1.29-.84v8.34c.3-.07,.58-.18,.83-.33,.25-.14,.46-.31,.65-.5,.18-.19,.32-.4,.42-.64,.1-.23,.15-.47,.15-.73Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M184.68,116.59h-.75l-.31-1.79h-1.83s-.33,1.79-.33,1.79h-.75l2.01-9.98,1.97,9.98Zm-1.17-2.35l-.8-4.49-.82,4.49h1.62Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M190.7,116.83l-2.72-6.61v6.37h-.75v-9.79l2.72,6.48v-6.24h.75v9.79Z'
                fill={textColor}
              />
              <path
                className='cls-1'
                d='M194.43,116.59h-.75v-9.56h.75v9.56Zm2.97,0h-.8l-2.17-5.02,1.97-4.54h.79l-1.96,4.54,2.17,5.02Z'
                fill={textColor}
              />
            </g>
          </g>
        </>
      )}
    </svg>
  )
})``

OldLogo.defaultProps = {
  color: 'primary',
  size: 'medium',
  variant: 'complete'
}

OldLogo.propTypes = {
  color: PropTypes.oneOf<Color>(['primary', 'secondary']),
  size: PropTypes.oneOf<Size>(['large', 'medium', 'small']),
  variant: PropTypes.oneOf<Variant>(['complete', 'mark', 'text'])
}
