import type { FC, ReactNode } from 'react'

import React from 'react'
import NextImage from 'next/image'
import { Box, Container } from '@mui/material'
import { APP_DISPLAY_NAME } from '@/configs/constants'

// import AuthBanner from './banner'

interface FocusLayoutProps {
  children?: ReactNode
  showInstLogo?: boolean
}

const FocusLayout: FC<FocusLayoutProps> = ({ children, showInstLogo }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      {/* <AuthBanner /> */}
      <Container maxWidth='sm' sx={{ py: { xs: '60px', md: '120px' } }}>
        {showInstLogo && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6
            }}
          >
            <NextImage
              alt={APP_DISPLAY_NAME}
              src='/logo-x128.png'
              height={128}
              width={128}
            />
          </Box>
        )}

        {children}
      </Container>
    </Box>
  )
}

FocusLayout.defaultProps = {
  showInstLogo: false
}

export default FocusLayout
