import type { FC } from 'react'

import React from 'react'
import toast from 'react-hot-toast'
import { useRouter } from 'next/router'
import { Box, Button, TextField } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'

import logger from '@/utils/console'
import { fetcher } from '@/utils/api-utils'
import { useMounted } from '@/utils/hooks'
import { Mixpanel } from '@/utils/mixpanel-instance'

const log = logger('form:login')

interface IFormInput {
  email: string
  password: string
}

interface AuthData {
  isLoggedIn: boolean
  user: UserData
}

interface UserData {
  isSuperuser: string
  providerId: string
  avatar: string
  email: string
  name: string
  uid: string
}

export const LoginForm: FC = (props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const isMounted = useMounted()
  const router = useRouter()

  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm<IFormInput>({
    defaultValues: {
      email: '',
      password: ''
    },
    mode: 'onBlur'
  })

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { email, password } = data
    setIsLoading(true)
    toast.dismiss()

    try {
      const res: AuthData = await fetcher('/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password
        })
      })
      Mixpanel.identify(res.user.uid)
      Mixpanel.register({
        Email: res.user.email,
        Name: res.user.name,
        Provider: res.user.providerId
      })
      Mixpanel.track('Successful Login')

      toast.success('You have been logged in.')
      const returnUrl = router.query.redirect as string | undefined
      // TODO : Put back to overview
      // router.push(returnUrl || '/overview')
      router.push(returnUrl || '/customers')
    } catch (err) {
      log.error({ err }, 'login failed with ERROR')
      toast.error('Incorrect email or password.')
    } finally {
      if (isMounted()) {
        setIsLoading(false)
      }
    }
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} {...props}>
      <TextField
        autoFocus
        fullWidth
        error={!!errors.email}
        helperText={errors?.email?.message}
        label='Email Address'
        margin='normal'
        type='text'
        inputProps={{
          'data-cy': 'email'
        }}
        {...register('email', {
          required: 'Email Address is required',
          maxLength: {
            value: 64,
            message: 'Email Address cannot be longer than 64 characters'
          }
        })}
      />
      <TextField
        fullWidth
        error={!!errors.password}
        helperText={errors?.password?.message}
        label='Password'
        margin='normal'
        type='password'
        inputProps={{
          'data-cy': 'password'
        }}
        {...register('password', {
          required: 'Password is required',
          maxLength: {
            value: 64,
            message: 'Password cannot be longer than 64 characters'
          }
        })}
      />
      <Box sx={{ mt: 2 }}>
        <Button
          disabled={isLoading}
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          data-cy='log-in-button'
        >
          Log In
        </Button>
      </Box>
    </form>
  )
}
