import type { GetServerSidePropsContext } from 'next'
import type { NextPageWithLayout } from '@/types/app-webui'

import React from 'react'
import Head from 'next/head'
import NextLink from 'next/link'
import { Box, Card, Container, Divider, Link } from '@mui/material'
import { withIronSessionSsr } from 'iron-session/next'

import { APP_DISPLAY_NAME } from '@/configs/constants'
import { sessionOptions } from '@/configs/session'
import { LoginForm } from '@/components/authentication/login-form'
import { OldLogo } from '@/components/old-logo'

import FocusLayout from '@/layouts/focus-layout'
import { Mixpanel } from '@/utils/mixpanel-instance'

const LoginPage: NextPageWithLayout = () => {
  React.useEffect(() => {
    Mixpanel.track('Login Page')
  }, [])

  return (
    <>
      <Head>
        <title>{`Login | ${APP_DISPLAY_NAME}`}</title>
      </Head>
      <Box
        component='main'
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth='sm'
          sx={{
            py: {
              xs: '60px',
              md: '120px'
            }
          }}
        >
          <Card elevation={16} sx={{ p: 1 }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <NextLink href='/' passHref>
                <a>
                  <OldLogo login={true} />
                </a>
              </NextLink>
              {/* <Typography color='textSecondary' sx={{ mt: 2 }} variant='body2'>
                {'By logging in you agree to the '}
                <NextLink href={'/terms-of-use'} passHref>
                  <Link variant='body2'>Terms of Use</Link>
                </NextLink>
                .
              </Typography> */}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
                p: 3
              }}
            >
              <LoginForm />
            </Box>
            <Divider sx={{ my: 3 }} />
            <Box sx={{ mt: 1, p: 3 }}>
              <NextLink href={'/password-recovery'} passHref>
                <Link
                  color='textSecondary'
                  variant='body2'
                  data-cy='forgot-password-link'
                  sx={{
                    textAlign: 'center',
                    display: 'block'
                  }}
                >
                  Forgot password?
                </Link>
              </NextLink>
            </Box>
          </Card>
        </Container>
      </Box>
    </>
  )
}

LoginPage.getLayout = (page) => <FocusLayout>{page}</FocusLayout>

export default LoginPage

export const getServerSideProps = withIronSessionSsr(async function ({
  req
}: GetServerSidePropsContext) {
  const user = req.session?.user

  if (user) {
    return {
      redirect: {
        // TODO : Put back to overview
        // destination: '/overview',
        destination: '/customers',
        permanent: false
      }
    }
  }

  return {
    props: {}
  }
},
sessionOptions)
