import mixpanel from 'mixpanel-browser'

if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
}

const actions = {
  identify: (id: string) => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.identify(id)
    }
  },
  alias: (id: string) => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.alias(id)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (name: string, props?: { [index: string]: any }) => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.track(name, props)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (props: { [index: string]: any }) => {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.register(props)
    }
  }
}

export const Mixpanel = actions
